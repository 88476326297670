import CVFile from '../res/andre-rego-cv.pdf'

function SocialsComponent () {
    return (
        <div className="socials flex">
            <a className="sm:mx-5 mx-3" href="mailto:andre.rego1992@gmail.com">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="sm:h-8 h-6 sm:w-8 w-6 text-gray-500"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                >
                    <path d="M22.288 21H1.712c-.945.0-1.712-.767-1.712-1.712V5.712c0-.945.767-1.712 1.712-1.712h20.576C23.233 4 24 4.767 24 5.712v13.576c0 .945-.767 1.712-1.712 1.712zM12 14.914 2.658 8.431 2.638 20h18.684V8.431L12 14.914zM20.869 5H3.08L12 11.229S18.252 6.823 20.869 5z"></path>
                </svg>
            </a>
            <a className="sm:mx-5 mx-3" href="https://github.com/andreregosd/" target="blank">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="sm:h-8 h-6 sm:w-8 w-6 text-gray-500"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                >
                    <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
                </svg>
            </a>
            <button className="sm:mx-5 mx-3 sm:mt-1 sm:px-2 px-1 sm:border-2 border border-gray-500 text-gray-500 text-xs sm:text-base font-bold">
                <a href={CVFile}  target='_blank'>Resumé</a>
            </button>
        </div>
    );
}

export default SocialsComponent;