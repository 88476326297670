function WellcomeComponent () {
    return (
        <div className="">
            <h3 className="text-2xl sm:text-4xl md:text-8xl font-extralight sm:mb-10 mb-6">Welcome!</h3>
            <div className="text-sm sm:text-base font-medium fake-border-left relative sm:pl-10 pl-6">
                <p>I am André Rego, a passionate computer science professional with over 8 years of experience in the dynamic realms of 
                ECommerce and Fintech.</p>
                <p>Driven by a relentless curiosity, I've embarked on a new frontier — the fascinating world of Web3. With a deep dive 
                into blockchain technology and the art of creating smart contracts in Solidity, I'm working hard to contribute to the evolution of decentralized applications.</p>
                <p>This website serves as a portal into my exploration of Web3 and showcases the projects that reflect my commitment 
                to continuous improvement.</p>
            </div>
        </div>
    );
}

export default WellcomeComponent;