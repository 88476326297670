export const projects = [
    {
        id: 1,
        title: "Chainlink VRF-Powered: NFT Minter",
        tab: "NFT Minter",
        url: "https://nft-minter-app-kappa.vercel.app/",
        frontendRepository: "https://github.com/andreregosd/nft-minter-app",
        backendRepository: "https://github.com/andreregosd/NFTMinter",
        etherscan: "https://sepolia.etherscan.io/address/0xeAa9a72c9604CE1d0bAdd2CD0F4BA0B93F0071F4",
        chain: "Sepolia",
        description: "A NFT collection of digital art powered by the Chainlink VRF to get a random generation. Anyone can mint for free a beautifull NFT of the collection 'Animals in tuxedo' and get surprised with the result. As a testament to decentralization, these NFTs find their home on the IPFS, ensuring a secure and immutable presence on the blockchain.",
    },
    {
        id: 2,
        title: "NFT Place",
        tab: "NFT Place",
        url: "https://nft-place-app.vercel.app/",
        frontendRepository: "https://github.com/andreregosd/nft-place-app",
        backendRepository: "https://github.com/andreregosd/nft-place",
        etherscan: "https://sepolia.etherscan.io/address/0xa64800E8eF721db85a1322B22Db43abCf885837C",
        chain: "Sepolia",
        description: "A NFT marketplace that is transparent, trustless and entirely decentralized. Unlike other marketplaces, NFT Place doesn't rely on any centralized components to store data like product descriptions or previous order. It's everything on-chain.",
    },
    {
        id: 3,
        title: "SmartSwap",
        tab: "SmartSwap",
        url: "https://smart-swap-app.vercel.app/",
        frontendRepository: "https://github.com/andreregosd/smart-swap-app",
        backendRepository: "https://github.com/andreregosd/smart-swap-contracts",
        etherscan: "https://sepolia.etherscan.io/address/0x727DFB244CfD31bff00dB9618Bf99D89eF266372",
        chain: "Sepolia",
        description: "A decentralized exchange on ethereum blockchain that allows users to swap ERC20 tokens. Also, users can become liquidity providers by adding liquidity to an existent liquidity pool or even creating new token pairs and gaining rewards as people use that liquidity to swap their tokens. SmartSwap uses the factory pattern to create new liquidity pools.",
    },
    {
        id: 4,
        title: "MultiSig Wallet Provider",
        tab: "MultiSig Wallet",
        url: "https://multisig-app-two.vercel.app/",
        frontendRepository: "https://github.com/andreregosd/multisig-app",
        backendRepository: "https://github.com/andreregosd/multisig-wallet-factory",
        etherscan: "https://sepolia.etherscan.io/address/0xD8A06F3c4e92C7cB061B4956D9872FAEAaaaB0e7",
        chain: "Sepolia",
        description: "Whether managing assets individually or collaboratively, this smart contract ensures the utmost security through multi-key authentication. Users can initiate the creation of their bespoke multi-signature wallet, defining key participants and required approvals. The smart contract follows the factory pattern to create new multi-signature wallets on demand.",
    }
];