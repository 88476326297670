import './App.css';
import logo from './res/icon.png';
import { useState } from 'react';
import { projects } from './data/projects';
import WellcomeComponent from './components/WellcomeComponent';
import ProjectComponent from './components/ProjectComponent';
import SocialsComponent from './components/SocialsComponent';

function App() {
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(true);
  const [activeProject, setActiveProject] = useState(projects[0]);

  return (
    <div className="App max-w-screen-xl m-auto p-4">
      <div className="inner-container relative">
      <header className="App-header relative max-w-screen-xl w-full z-20">
        <div className="flex flex-wrap items-center justify-between mx-auto pt-8 md:px-20 sm:px-10 px-5">
          <a href="#" onClick={ (e) => { e.preventDefault(); setShowWelcomeMessage(true); } }>
            <img src={logo} className="md:h-20 h-16 absolute md:top-6 top-4" alt="Logo" />
          </a>
          <SocialsComponent />
        </div>
      </header>
      <div className="body relative">
        <div className="projects flex flex-1">
          <div className="flex flex-1 self-center place-content-center py-10">
            <div className="grid flex-1 grid-cols-1 md:grid-cols-3">
              <div className="project-content col-span-2 md:px-20 sm:px-10 px-4 md:mb-0 mb-8">
                { showWelcomeMessage ? <WellcomeComponent /> : <ProjectComponent activeProject={activeProject} />}
              </div>
              <div className="projects-menu md:px-20 sm:px-10 px-4 flex self-center">
                <div className="w-full md:w-auto">
                  <div className="menu-header text-xl sm:text-2xl md:text-4xl sm:mb-6 mb-2">Projects</div>
                  <div className="menu-body relative">
                    <ul className="sm:pl-7 sm:py-8 relative">
                      <li className="cursor-pointer inline md:block" onClick={ () => { setShowWelcomeMessage(false); setActiveProject(projects[0]); } }>
                        <span className={"sm:text-sm text-xs hover:font-bold relative fake-border-bottom pb-1 mb-4 sm:mx-2 mx-1 md:inline-block " + (!showWelcomeMessage && activeProject.id == projects[0].id ? "font-bold" : "")}>
                          { projects[0].tab }
                        </span>
                      </li>
                      <li className="cursor-pointer inline md:block" onClick={ () => { setShowWelcomeMessage(false); setActiveProject(projects[1]); } }>
                      <span className={"sm:text-sm text-xs hover:font-bold relative fake-border-bottom pb-1 mb-4 sm:mx-2 mx-1 md:inline-block " + (!showWelcomeMessage && activeProject.id == projects[1].id ? "font-bold" : "")}>
                          { projects[1].tab }
                        </span>
                      </li>
                      <li className="cursor-pointer inline md:block" onClick={ () => { setShowWelcomeMessage(false); setActiveProject(projects[2]); } }>
                      <span className={"sm:text-sm text-xs hover:font-bold relative fake-border-bottom pb-1 mb-4 sm:mx-2 mx-1 md:inline-block " + (!showWelcomeMessage && activeProject.id == projects[2].id ? "font-bold" : "")}>
                          { projects[2].tab }
                        </span>
                      </li>
                      <li className="cursor-pointer inline mb:block" onClick={ () => { setShowWelcomeMessage(false); setActiveProject(projects[3]); } }>
                      <span className={"sm:text-sm text-xs hover:font-bold relative fake-border-bottom pb-1 mb-4 sm:mx-2 mx-1 md:inline-block " + (!showWelcomeMessage && activeProject.id == projects[3].id ? "font-bold" : "")}>
                          { projects[3].tab }
                        </span>
                      </li>
                    </ul>                       
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}

export default App;
