function ProjectComponent ({activeProject}) {
    return (
        <div className="">
            <h5 className="project-title text-xl sm:text-2xl md:text-4xl sm:mb-6 mb-2">{ activeProject.title }</h5>
            <div className="project-info font-medium fake-border-left relative sm:pl-10 pl-6">
                <div className="text-sm sm:text-base project-description">
                    { activeProject.description }
                </div>
                <div className="project-links mt-4 pb-2">
                    <a className="sm:text-sm text-xs hover:font-bold pb-1 sm:mx-2 mx-1 relative fake-border-bottom" target="blank" href={ activeProject.url }>Demo</a>
                    <a className="sm:text-sm text-xs hover:font-bold pb-1 sm:mx-2 mx-1 relative fake-border-bottom" target="blank" href={ activeProject.backendRepository }>Smart contracts</a>
                    <a className="sm:text-sm text-xs hover:font-bold pb-1 sm:mx-2 mx-1 relative fake-border-bottom" target="blank" href={ activeProject.frontendRepository }>Front end</a>
                    <a className="sm:text-sm text-xs hover:font-bold pb-1 sm:mx-2 mx-1 relative fake-border-bottom" target="blank" href={ activeProject.etherscan }>Etherscan</a>
                </div>
            </div>
        </div>
    );
}

export default ProjectComponent;